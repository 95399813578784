@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.MultiImageBanner {
  display: block;

  @include breakpoints.bpk-breakpoint-above-tablet {
    background-image: var(--background-image-desktop);
  }

  @include breakpoints.bpk-breakpoint-tablet {
    flex-direction: column-reverse;
    justify-content: start;
    background-image: var(
      --background-image-tablet,
      var(--background-image-desktop)
    );
  }

  @include breakpoints.bpk-breakpoint-small-tablet {
    flex-direction: column-reverse;
    justify-content: start;
    background-image: var(--background-image-mobile);
  }

  h2 {
    @include breakpoints.bpk-breakpoint-above-tablet {
      @include typography.bpk-hero-5;

      &:not(:last-child) {
        margin-bottom: tokens.bpk-spacing-md();
      }
    }

    @include breakpoints.bpk-breakpoint-tablet {
      @include typography.bpk-heading-2;

      &:not(:last-child) {
        margin-bottom: tokens.bpk-spacing-md();
      }
    }

    @include breakpoints.bpk-breakpoint-small-tablet {
      margin-bottom: tokens.bpk-spacing-md();
    }
  }

  &__disclaimer {
    margin-top: tokens.bpk-spacing-lg();

    @include breakpoints.bpk-breakpoint-mobile {
      margin-top: tokens.bpk-spacing-base();
    }
  }
}
